import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/views/Home.vue";
import Portfolio from "@/views/Portfolio.vue";
import Teaching from "@/views/Teaching.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
  },
  {
    path: "/teaching",
    name: "Teaching",
    component: Teaching,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
