








































































































































































import Vue from "vue";

import TopBarFab from "../components/TopBarFab.vue";

const flipfolder_actions = [
  {
    name: "mdi-web",
    link: "https://www.flipfolderapp.com/",
    asFab: true,
  },
  {
    name: "mdi-apple",
    link: "https://apps.apple.com/us/app/flipfolder/id1363344653",
    asFab: true,
  },
  {
    name: "mdi-google-play",
    link: "https://play.google.com/store/apps/details?id=com.marcusman.eflip&hl=en_US&gl=US",
    asFab: true,
  },
];

export default Vue.extend({
  name: "Home",
  components: {
    TopBarFab,
  },
  data: () => ({
    timeline: [
      {
        date: "2023",
        title: "J.W. Pepper & Son, Inc.",
        subtitle: "Director of System Architecture",
        content: `
              I become the Director of System Architecture at J.W. Pepper, and worked to create designs and implementations for financial systems (ERPs), digital sheet music products,
              ecommerce platforms, and more. Reporting to the Chief Information Officer, I worked with other directors to create a technology roadmap for the company.
            `,
      },
      {
        date: "2022",
        content: [
          {
            title: "J.W. Pepper & Son, Inc.",
            subtitle: "Reading Session App",
            content: `
              I created and released an Android/iOS/Web app at J.W. Pepper for facilitating music Reading Sessions.
            `,
            actions: [
              {
                name: "mdi-web",
                link: "https://readingsession.com",
                asFab: true,
              },
              {
                name: "mdi-apple",
                link: "https://apps.apple.com/us/app/reading-session/id1610785056",
                asFab: true,
              },
              {
                name: "mdi-google-play",
                link: "https://play.google.com/store/apps/details?id=com.jwpepper.smc.readingsession",
                asFab: true,
              },
            ],
          },
          {
            subtitle: "ePrint",
            content: `
              Designed and developed a revamp of J.W. Pepper's electronic online sheet music printing system.
            `,
            actions: [
              {
                name: "mdi-web",
                link: "https://www.jwpepper.com/sheet-music/eprint-digital-sheet-music.jsp",
                asFab: true,
              },
            ],
          },
        ],
        isMultiple: true,
      },
      {
        date: "2021",
        title: "BandSavvy LLC Asset Sale",
        subtitle: "Lead Software Designer",
        content: `
          FlipFolder App was sold to J.W. Pepper & Son, Inc. in July of 2021. I began working full time supporting FlipFolder App and re-inventing J.W. Pepper's
          other digital sheet music products.
        `,
        actions: [
          {
            name: "mdi-text-box",
            link: "https://www.prnewswire.com/news-releases/jw-pepper-announces-acquisition-of-bandsavvy-llc-creators-of-the-flipfolder-app-301327668.html",
            asFab: true,
          },
        ],
      },
      {
        date: "2020-2021",
        content: [
          {
            title: "BandSavvy LLC",
            subtitle: "Founder",
            content: `
              During the pandemic, FlipFolder App took off with up to 60+ Universities and High Schools around the United States using the app.
            `,
            actions: flipfolder_actions,
          },
          {
            title: "Grant Street Group",
            subtitle: "Software Developer",
            content: `
              Began working full-time for Grant Street Group. Used Perl extensively to work on their TaxSys product. Included working on many highly
              technical algorithms for calculating property taxes in Florida and California.
            `,
          },
        ],
        isMultiple: true,
      },
      {
        date: "2019",
        content: [
          {
            title: "University of Pittsburgh",
            subtitle: "Graduated",
            content: `
              Graduated Summa Cum Laude from the University of Pittsburgh with a bachelors of science in Computer Science & Music Composition.
            `,
          },
          {
            subtitle: "Algorithm Implementation Teaching Assistance",
            content: `
              Teacher for a weekly recitation for Pitt's Algorithm Implementation course. One of the
              first undergraduates to ever teach a recitation for this course. Included teaching advanced
              data structures from Indexable Min Priority Queues to cryptography and compression algorithms.
            `,
          },
          {
            title: "Capital One",
            subtitle: "Tech Intern",
            content: `
              Helped save the company money by analyzing AWS cloud instances in mass for usage statistics,
              algorithmically predicting and suggesting how instances could be downsized.
            `,
          },
        ],
        isMultiple: true,
      },
      {
        date: "2018",
        content: [
          {
            title: "BandSavvy LLC",
            subtitle: "Founder",
            content: `
             Created an electronic music viewer and group performance manager called FlipFolder App while in the University of Pittsburgh marching band.
            `,
            actions: flipfolder_actions,
          },
          {
            title: "Open Source Work",
            subtitle: "Many-Cloud NPM Module",
            content: `
          Released an open-source NPM module for multi-cloud storage abstraction. Allows authenticating to multiple
          cloud storage solutions and then provides a simple file/folder abstraction layer.
        `,
            actions: [
              {
                name: "mdi-github",
                link: "https://github.com/marcdubs/many-cloud",
                asFab: true,
              },
              {
                name: "mdi-npm",
                link: "https://www.npmjs.com/package/many-cloud",
                asFab: true,
              },
            ],
          },
          {
            title: "UPMC Enterprises",
            subtitle: "Summer Associate",
            content: `
              Made a REST API in NodeJS and a front-end in ReactJS. Used test-driven-development to build an accessible
              web form for signing up to an e-pharmacy.
            `,
            actions: [
              {
                name: "mdi-web",
                link: "https://rx.upmc.com/ship-my-meds/",
                asFab: true,
              },
            ],
          },
        ],
        isMultiple: true,
      },
      {
        date: "2017-2018",
        title: "University of Pittsburgh",
        subtitle: "Data Structures Teaching Assistant",
        content: `
          Taught 6 recitations under 3 different professors in Data Structures. Included creating and grading assignments.
          I also tutored students in the CRC (Computer Science Resource Center).
        `,
      },
      {
        date: "2017",
        title: "Grant Street Group",
        subtitle: "Software Developer Internship",
        content: `
          My first technology internship. I worked on county-level government tax applications and on a multi-vendor auction platform through 2 different intern projects.
        `,
      },
    ],
  }),
});
