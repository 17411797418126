import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import Upwork from "@/components/icons/Upwork.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#270722", //Lavender web: #D8DDEF
        secondary: "#69747C",
        accent: "#BA1200",
      },
      dark: {
        primary: "#07020D", //True blue: #3066BE
        secondary: "#69747C",
        accent: "#BA1200",
      },
    },
    dark: true,
  },
  icons: {
    values: {
      upwork: {
        component: Upwork,
      },
    },
  },
});
