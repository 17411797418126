









































































































































import Vue from "vue";

export default Vue.extend({
  name: "Portfolio",
  data: () => ({
    portfolioContent: [
      {
        title: "Reading Session",
        image: "/portfolio/readingsession.png",
        flex: 6,
        shortDescription: `
          Reading Session was an app I created for J.W. Pepper that is a sheet music viewer catered specifically for reading sessions at the
          hundreds of live events that J.W. Pepper is a part of every year.
        `,
        longDescription: [
          `Similar to FlipFolder, the Reading Session app was made to be completely cross-platform on all of iOS/Android/Web, with over 90% of
           the code shared between all 3 of these distributions, with the remaining 10% existing as device and runtime translation.
          `,
          `This app was created over only a period of 3 months, and supports all of J.W. Pepper's sheet music aspect ratio and formats on all
           screen sizes.`,
        ],
        links: [
          {
            name: "Google Play",
            link: "https://play.google.com/store/apps/details?id=com.jwpepper.smc.readingsession",
            accent: true,
          },
          {
            name: "Apple Store",
            link: "https://apps.apple.com/us/app/reading-session/id1610785056",
            accent: true,
          },
          {
            name: "Web Version",
            link: "https://readingsession.com",
            accent: true,
          },
          {
            name: "Other Tools I worked on for J.W. Pepper",
            link: "https://www.youtube.com/watch?v=JkUb8VOPSg8",
            accent: false,
          },
        ],
        expanded: false,
      },
      {
        title: "FlipFolder",
        image: "/portfolio/flipfolder.png",
        flex: 6,
        shortDescription: `
          FlipFolder is an iOS/Android mobile app including a sheet music viewer and group performance manager made for Marching Bands.
        `,
        longDescription: [
          `FlipFolder was created while I was a student at the University of Pittsburgh and a member of the marching band.`,
          `It uses a custom made BlueTooth mesh network and a scalabe clustered computing network to manage live performances, allowing
           band directors to bring up music on all of the devices in the band in real time.`,
          `The product spawned a successful business, called BandSavvy LLC, which grew in the niche of sheet music/marching band software.`,
          `FlipFolder was sold to J.W. Pepper & Son, Inc., the largest sheet music distributor in the United States, as part of an asset sale in July of 2021.
           The product continues to grow, and is now used by hundreds of Colleges and K-12 marching bands across the United States.
          `,
        ],
        links: [
          {
            name: "Website",
            link: "https://home.flipfolderapp.com/",
            accent: true,
          },
          {
            name: "Google Play",
            link: "https://play.google.com/store/apps/details?id=com.marcusman.eflip&hl=en_US&gl=US",
            accent: true,
          },
          {
            name: "Apple Store",
            link: "https://apps.apple.com/us/app/flipfolder/id1363344653",
            accent: true,
          },
        ],
        backgroundPosition: "center 18%",
        expanded: false,
      },
      {
        title: "UniClip/TBoneClip/WristClip",
        image: "/portfolio/clips.png",
        flex: 6,
        shortDescription: `
          To go alongside FlipFolder, I designed and set up the manufacturing supply chain for 3 phone clips that attach to various instruments.
        `,
        longDescription: [
          `Before custom manufactured phone clips existed, FlipFolder customers would often come up with their own hacky attachment hardware to
           get smartphones attached to instruments.`,
          `To better accomodate FlipFolder customers, I learned engineering and drawing on the side and came up with drawings and a supply chain
           for 3 different clips that end up accomodating the full band.`,
          `The UniClip (on the left) is a lyre-mounted clip and uses the universal instrument lyre mounting system.`,
          `The TBoneClip (in the middle) is a wrap-around bell clamp design made for Trombones, and the lever arm in the middle allows for
           adjustment so that the player's mobile phone does not block a significant amount of peripheral vision as they are marching.`,
          `The WristClip (on the right) is made for Flute players or Sousaphone players who do not have an instrument capable of having a phone
           directly mounted, so instead they wear an arm band with their phone attached.`,
          `These were sold to J.W. Pepper & Son, Inc., the largest sheet music distributor in the United States, as part of an asset sale in July of 2021 which
           also included the FlipFolder software.`,
        ],
        links: [
          {
            name: "Product Page",
            link: "https://home.flipfolderapp.com/instrument-clips/",
            accent: true,
          },
        ],
        expanded: false,
      },
      {
        title: "Mercenary Management Simulator",
        image: "/portfolio/ld44.png",
        flex: 6,
        shortDescription: `
          Mercenary Management Simulator 2020 is a video game made in 72 hours for Ludum Dare 44 Jam with my friend Ethan DeFrank.
        `,
        longDescription: [
          `
          Mercenary Management Simulator 2020 is a video game made in 72 hours for Ludum Dare 44 Jam with my friend Ethan DeFrank.
        `,
          `
          We used a web-based platform called Pixi.js and dabbled with some fancy ray casting for hit detection.
        `,
          `
          This game also has a physics engine and a working AI so that you can play against the computer.
        `,
        ],
        links: [
          {
            name: "Play the Game",
            link: "https://ld44.marcusman.com/",
            accent: true,
          },
          {
            name: "Information",
            link: "https://ldjam.com/events/ludum-dare/44/mercenary-management-simulator-2020",
            accent: true,
          },
          {
            name: "Ethan DeFrank's Portfolio",
            link: "https://ethandefrank.com/",
          },
        ],
        expanded: false,
      },
      {
        title: "Brass Arrangements",
        image: "/portfolio/conducting.png",
        flex: 6,
        shortDescription: `
          A collection of Brass Arrangements I arranged for the Pitt Band, and a couple that I multitracked.
        `,
        longDescription: [
          `
          I arranged all of these tunes for brass using Finale or Musescore, added my own flair to it, and either performed it myself or got the Pitt Band low brass to play them :).
        `,
        ],
        links: [
          {
            name: "Moon River (Pitt Band)",
            link: "https://youtu.be/arvaNeFz1jo",
            accent: true,
          },
          {
            name: "Sound of Silence (Pitt Band)",
            link: "https://youtu.be/hwKM7aUhGuw",
            accent: true,
          },
          {
            name: "King's Arrival (Multitrack)",
            link: "https://www.youtube.com/watch?v=fLJMcDG2CyU",
            accent: false,
          },
          {
            name: "Positive Thinking (Multitrack)",
            link: "https://youtu.be/YDPSq2QUao4",
            accent: false,
          },
          {
            name: "Moon River (Another View)",
            link: "https://youtu.be/b4bs42g1cIU",
            accent: false,
          },
        ],
        expanded: false,
      },
      {
        title: "Fervor of Ambition",
        image: "/portfolio/fervorofambition.png",
        flex: 6,
        shortDescription: `
          While completing my degree in Music Composition at the University of Pittsburgh, I wrote a 5 minute piece for full orchestra called "Fervor of Ambition."
        `,
        longDescription: [
          `
          Under the guidance of professor Roger Zahab, I got largely out of my comfort zone and wrote a short piece for full orchestra during my senior year. This was
          then performed by the University of Pittsburgh orchestra after a couple practices.
          `,
          `I'm a brass player at heart, so naturally the piece had to have a Trumpet solo and then a Trombone solo :).`,
        ],
        links: [
          {
            name: "Listen",
            link: "https://www.youtube.com/watch?v=x0hnmWWAn5o",
            accent: true,
          },
        ],
        expanded: false,
      },
      {
        title: "Many Cloud",
        image: "/portfolio/manycloud.png",
        flex: 6,
        shortDescription: `
          Many Cloud is an open source utility that creates an interface for many cloud platforms found online that, after authentication,
          abstracts out which cloud service you are manipulating for the sake of simplicity.
        `,
        longDescription: [
          `Many Cloud is an open source utility that creates an interface for many cloud platforms found online that, after authentication,
          abstracts out which cloud service you are manipulating for the sake of simplicity. Many Cloud keeps things simple by
          automatically refreshing OAuth2 tokens and by providing an object-oriented approach to dealing with Files and Folders.`,
        ],
        links: [
          {
            name: "NPM",
            link: "https://www.npmjs.com/package/many-cloud",
            accent: true,
          },
          {
            name: "GitHub",
            link: "https://github.com/marcdubs/many-cloud",
            accent: true,
          },
        ],
        expanded: false,
      },
      {
        title: "RegionWars",
        image: "/portfolio/regionwars.png",
        flex: 6,
        shortDescription: `
          RegionWars is a game made in NodeJS and a HTML5 canvas in which 2 or more players fight to win
          in an arena where there are 3 regions each with their own bonus ability.
        `,
        longDescription: [
          `
            The game server and client is programmed in NodeJS and the map generation client is programmed in LibGDX.
          `,
          `
            The Timelapse link shows the course of the whole project took. I started with testing out a new type of map generation
            I'd been interested in called Voronoi diagrams. For those of you interested, the map generation also used Lloyd's
            relaxation and for a more realistic pseudo-randomization it used Perlin noise.
          `,
          `
            I also anticipated writing the entire game with LibGDX but over the course of the game's development decided the
            project should be moved over to NodeJS since the most dominant feature of the project is networking and Socket.io
            is a fantastic, fast, and easy protocol. This also allows for a more wide range of devices to play the game.
          `,
        ],
        links: [
          {
            name: "Play the Game",
            link: "http://regionwars.dock.marcusman.com/",
            accent: true,
          },
          {
            name: "Timelapse Video",
            link: "https://youtu.be/HRJemPFFmxI",
          },
          {
            name: "Voronoi Diagrams",
            link: "https://en.wikipedia.org/wiki/Voronoi_diagram",
          },
          {
            name: "LLoyd's Relaxation",
            link: "https://www.jasondavies.com/lloyd/",
          },
          {
            name: "Perlin Noise",
            link: "https://en.wikipedia.org/wiki/Perlin_noise",
          },
          {
            name: "Socket.io",
            link: "https://socket.io/",
          },
        ],
        expanded: false,
      },
      {
        title: "Bad Driver",
        image: "/portfolio/baddriver.webp",
        flex: 6,
        shortDescription: `Android & iOS game made with LibGDX. Bob and weave your way through traffic like a bad driver.`,
        longDescription: [
          `Live the life of a Bad Driver! Bob and weave your way through traffic at high speeds in order to reach your destination the fastest..... please don't do this on an actual highway.`,
          `It's essentially a 2D top-down racing game where you have to click 1 of 3 lanes to continuously dodge vehicles you are trying to pass. `,
          `It's very simple game made for all ages to be played at your leisure.`,
        ],
        links: [
          {
            name: "Google Play",
            link: "https://play.google.com/store/apps/details?id=com.marcusman.baddriver",
            accent: true,
          },
          {
            name: "Apple Store",
            link: "https://apps.apple.com/us/app/bad-driver-swerve-through-traffic/id1143833110?ls=1",
            accent: true,
          },
        ],
        expanded: false,
      },
      {
        title: "Bowed",
        image: "/portfolio/bowed.png",
        flex: 6,
        shortDescription: `My first NodeJS, Socket.io, and HTML5 Canvas game.`,
        longDescription: [
          `This is the first NodeJS, Socket.io, and HTML5 game that I made.`,
          `Requires at least two players, and you have a crossbow war with friends inside a web browser.`,
        ],
        links: [
          {
            name: "Play the Game",
            link: "http://bowed.marcusman.com/",
            accent: true,
          },
        ],
        expanded: false,
      },
      {
        title: "Perl Projects",
        image: "/portfolio/perlprojects.png",
        flex: 6,
        shortDescription:
          "A small collection of projects I made using Perl and the Mojolicious Web Framework. I don't normally use Perl, but I made these to prepare for a job at the time.",
        longDescription: [
          `A small collection of projects I made using Perl and the Mojolicious Web Framework. I don't normally use Perl, but I made these to prepare for a job at the time.`,
          `Pollite is a strawpoll clone that allows to user to enter a poll title and 4 options. Then a custom link is created for that poll.`,
          `Runescape Item Quick is an item quiz based on all of the items in one of my favorite games, Old School Runescape.
           This program finds a random item and then either creates an easy question with other random items or more likely
           creates a more challenging question by finding other items containing similar words.`,
        ],
        links: [
          {
            name: "Pollite",
            link: "http://pollite.marcusman.com/",
            accent: true,
          },
          {
            name: "Runescape Item Quiz",
            link: "http://items.marcusman.com/",
            accent: true,
          },
        ],
        expanded: false,
      },
    ],
  }),
});
