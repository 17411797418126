














import Vue from "vue";

export default Vue.extend({
  name: "TopBarFab",
  props: {
    href: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    //
  }),
});
