
















































































import Vue from "vue";

import TopBarFab from "@/components/TopBarFab.vue";

export default Vue.extend({
  name: "Portfolio",
  data: () => ({
    javaGameProgrammingSections: [
      {
        title:
          "Intro Lessons (for an understanding of the Java graphics libraries) (it's recommended to skip to the Graphics Lessons)",
        lessons: [
          {
            title: "Lesson 1 - Setup and Graphics Buffering",
            link: "https://youtu.be/jZYPw7-suKo",
          },
          {
            title: "Lesson 2 - Handling Threads",
            link: "https://youtu.be/yTiATcIpxd4",
          },
          {
            title: "Lesson 3 - Render/Update and accessing pixels",
            link: "https://youtu.be/5j_ABIp1BjA",
          },
        ],
      },
      {
        title: "Graphics Lessons",
        workspaceDownload:
          "https://github.com/marcdubs/java-game-programming-tutorials/tree/Lesson_03",
        lessons: [
          {
            title: "Lesson 4 - Rendering Images",
            link: "https://youtu.be/5cxaKsazlBQ",
          },
          {
            title: "Lesson 5 - Rectangles! Yep...Rectangles!",
            link: "https://youtu.be/QPsuj8YCwfo",
          },
          {
            title: "Lesson 6 - Sprites and Transparency",
            link: "https://youtu.be/bMeYTsnyKvM",
          },
        ],
      },
      {
        title: "Input & Mapping",
        workspaceDownload:
          "https://github.com/marcdubs/java-game-programming-tutorials/tree/Lesson_06",
        lessons: [
          {
            title: "Lesson 7 - Tiles and Maps",
            link: "https://youtu.be/zeWyRNxd2v0",
          },
          {
            title: "Lesson 8 - Player and Input",
            link: "https://youtu.be/MoaV0OLHF9s",
          },
          {
            title: "Lesson 9 - Editing/Saving Map",
            link: "https://youtu.be/xjQNEaqtkGI",
          },
        ],
      },
      {
        title: "Utilities",
        workspaceDownload:
          "https://github.com/marcdubs/java-game-programming-tutorials/tree/Lesson_09",
        lessons: [
          {
            title: "Lesson 10 - Animated Sprite",
            link: "https://youtu.be/BCZ4Ksl8x_c",
          },
          {
            title: "Lesson 11 - Animated Player",
            link: "https://youtu.be/49hoO_S5nqw",
          },
          {
            title: "Lesson 12 - Graphical User Interface and Tile Palette",
            link: "https://youtu.be/CcTnfnYCIdw",
          },
        ],
      },
      {
        title: "Additions",
        workspaceDownload:
          "https://github.com/marcdubs/java-game-programming-tutorials/tree/Lesson_12",
        lessons: [
          {
            title: "Lesson 13 - Making a Platformer",
            link: "https://youtu.be/7aexFvOxnjU",
          },
          {
            title:
              "Lesson 14 - Map Layers (Game Resizing, Faster map rendering [for large maps], and layers)",
            link: "https://youtu.be/y0cYIsTYPCs",
          },
          {
            title: "Lesson 15 - Advanced Map Layering and Collisions",
            link: "https://youtu.be/kddQb1dy8R8",
          },
        ],
      },
    ],
  }),
  components: { TopBarFab },
});
