












































































import Vue from "vue";

import TopBarFab from "./components/TopBarFab.vue";

export default Vue.extend({
  name: "App",
  components: {
    TopBarFab,
  },
  data: () => ({
    displayNext: true,
  }),
  beforeMount: function () {
    const state = localStorage.getItem(
      "MARCUSDUBREUIL_PORTFOLIO_DARK_MODE_OFF"
    );

    if (state === "true") {
      this.$vuetify.theme.dark = false;
    }
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "MARCUSDUBREUIL_PORTFOLIO_DARK_MODE_OFF",
        !this.$vuetify.theme.dark + ""
      );
    },
  },
  watch: {
    $route() {
      this.displayNext = false;
    },
  },
});
